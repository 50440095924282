import { useState, useEffect } from 'react'
import { useLocation, Outlet, useSearchParams } from 'react-router-dom'

import Progress from './ProgressBar'

/**
 * Defines the navigation UI for the input form.
 * @returns {JSX}
 */
export default function BuildApplication(){

  // hard coding planYear here!
  const [application, setApplication] = useState( {planYear:'2024', source:''} )
  const [, setSearchParams] = useSearchParams()
  const location = useLocation()

  useEffect( () => {
    if (location.search) {
      const sp = new URLSearchParams(location.search)
      setApplication( JSON.parse(sp.get('application')) )
      setSearchParams("")
    }
  })

  return (
    <div className="main form max-w-3xl mx-auto my-14">
      <Progress />
      <Outlet context={{application, setApplication}}/>
    </div>
  )
}

const ROUTE_SEUQENCE = {
  'open-enrollment': [
    'location'
    , 'applicants'
    , 'household'
    , 'subsidy'
    // , 'specialEnrollment'
    , 'providers'
    , 'drugs'
    , 'healthStatus'
    , 'preferences'
    , 'submit'
  ]
  , 'special-enrollment': [
    'location'
    , 'applicants'
    , 'household'
    , 'subsidy'
    , 'specialEnrollment'
    , 'providers'
    , 'drugs'
    , 'healthStatus'
    , 'preferences'
    , 'submit'
  ]
}

export function getRoute(planYear, location, isNext=true){
  
  // find which route sequence to follow
  let sequence
  if (planYear == '2024') {
    sequence = ROUTE_SEUQENCE['open-enrollment']
  } else {
    sequence = ROUTE_SEUQENCE['special-enrollment']
  }

  // find name of current route within app flow
  const currentRoute = location.pathname.split('/').slice(-1)[0]

  // index into the next or previous route
  const routeIndex = sequence.indexOf(currentRoute)
  let newRoute
  if (isNext) {
    newRoute =  sequence[routeIndex + 1]
  } else {
    newRoute= sequence[routeIndex - 1]
  }

  return ('/application/' + newRoute)

}